<template>
  <div>
    <div class="bannerContainer">
      <div class="banner w">
        <div class="title">{{ $t('OSAI云平台') }}</div>
        <div class="info">
          {{
            $t(
              '实现云端数据同步、设备监控、权限管理，建立高效的数据分析和管理体系。'
            )
          }}
        </div>
      </div>
    </div>

    <div class="content">
      <div
        :class="$i18n.locale == 'en' ? 'en_topContainer' : 'zh_topContainer'"
      >
        <div class="top w">
          <div class="text">
            {{
              $t(
                '傲视云平台打通产品和服务的连接，建立高效的统计分析和设备管理。'
              )
            }}
          </div>
        </div>
      </div>
      <div class="bottomContainer">
        <div class="bottom w">
          <div class="left">
            <div :class="$i18n.locale == 'en' ? 'en_item_top' : 'zh_item_top'">
              <div class="text">
                {{
                  $t(
                    '支持各终端、部门和门店等颗粒度的工作和经营情况分析和统计。'
                  )
                }}
              </div>
            </div>
            <div class="item_bottom"></div>
          </div>
          <div class="right">
            <div
              :class="$i18n.locale == 'en' ? 'en_item_top' : 'zh_item_top'"
            ></div>
            <div
              :class="
                $i18n.locale == 'en' ? 'en_item_bottom' : 'zh_item_bottom'
              "
            >
              <div class="text">
                {{
                  $t('支持自定义多类型的通知和告警自动推送，支持多种推送方式。')
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.bannerContainer {
  background: url(../../../assets/云平台/云服务.png.png) no-repeat center;
  background-size: cover;
  .banner {
    padding: 122px 0px 0px 134px;
    height: 480px;
    .title {
      line-height: 104.26px;
      color: #344f98;
      font-size: 72px;
      font-weight: 700;
    }
    .info {
      font-size: 24px;
      width: 490px;
      font-size: 24px;
      color: rgba(128, 128, 128, 1);
      line-height: 34.75px;
    }
  }
}
.content {
  .zh_topContainer {
    background: url(../../../assets/云平台/数据同步配图.png) no-repeat center;
    background-size: cover;
    .top {
      height: 400px;
      padding-top: 274px;
      padding-left: 520px;
      .text {
        width: 665px;
        color: #808080;
        line-height: 28.96px;
        font-size: 20px;
      }
    }
  }
  .en_topContainer {
    background: url(../../../assets/云平台/数据同步配图en.png) no-repeat center;
    background-size: cover;
    .top {
      height: 400px;
      padding-top: 274px;
      padding-left: 520px;
      .text {
        width: 665px;
        color: #808080;
        line-height: 28.96px;
        font-size: 20px;
      }
    }
  }
  .bottomContainer {
    background: #f0f5ff;
    .bottom {
      display: flex;
      .left {
        width: 50%;
        .zh_item_top {
          display: flex;
          justify-content: center;
          .text {
            width: 480px;
            color: #808080;
            line-height: 28.96px;
            font-size: 20px;
            padding-top: 473px;
          }
          background: url(../../../assets//云平台/自定义信息配图.png) no-repeat
            center;
          background-size: cover;
          height: 600px;
        }
        .en_item_top {
          display: flex;
          justify-content: center;
          .text {
            width: 480px;
            color: #808080;
            line-height: 28.96px;
            font-size: 20px;
            padding-top: 473px;
          }
          background: url(../../../assets/云平台/自定义信息配图en.png) no-repeat
            center;
          background-size: cover;
          height: 600px;
        }
        .item_bottom {
          background: url(../../../assets//云平台/后台数据配图.png) no-repeat
            center;
          background-size: cover;
          height: 384px;
        }
      }
      .right {
        width: 50%;
        .zh_item_top {
          background: url(../../../assets//云平台/登录界面配图.png) no-repeat
            center;
          background-size: cover;
          height: 384px;
        }
        .en_item_top {
          background: url(../../../assets/云平台/登录界面配图en.png) no-repeat
            center;
          background-size: cover;
          height: 384px;
        }
        .zh_item_bottom {
          display: flex;
          justify-content: center;
          .text {
            width: 480px;
            color: #808080;
            line-height: 28.96px;
            font-size: 20px;
            padding-top: 439px;
          }
          background: url(../../../assets//云平台/分组\ 16.png) no-repeat center;
          background-size: cover;
          height: 600px;
        }
        .en_item_bottom {
          display: flex;
          justify-content: center;
          .text {
            width: 480px;
            color: #808080;
            line-height: 28.96px;
            font-size: 20px;
            padding-top: 439px;
          }
          background: url(../../../assets//云平台/分组\ 16en.png) no-repeat
            center;
          background-size: cover;
          height: 600px;
        }
      }
    }
  }
}
</style>
